import queryString from 'query-string';
import {
  ALLOWED_CONFIG_PARAM_KEYS,
  configs,
  domainMappings,
} from './utils/environmentInitUtils';


const urlParams = {
  ...queryString.parse(window.location.search, {parseBooleans: true}),
};

Object.keys(urlParams).forEach(key => {
  if (!ALLOWED_CONFIG_PARAM_KEYS.includes(key)) {
    delete urlParams[key];
  }
});

const defaultEnvironmentSettings = {
  // injected from webpackBaseConfig.js
  // eslint-disable-next-line no-undef
  lens_version: __LENS_VERSION__,
  // eslint-disable-next-line no-undef
  lens_startTime: __LENS_START_TIME__,
  // eslint-disable-next-line no-undef
  lens_spaPipelineAppId: __LENS_SPA_PIPELINE_APP_ID__ ,
  // eslint-disable-next-line no-undef
  lens_publicAssetsPath: __LENS_PUBLIC_ASSETS_PATH__,
  // eslint-disable-next-line no-undef
  lens_publicAppPath: __LENS_PUBLIC_APP_PATH__,
  // we use the domain name to determine the environment by default
  lens_env: domainMappings[location.hostname] || 'prod',
  // this sets the launch on launch version to be our testing version
  // it also sets the environment to be local and disables error reporting
  lens_dev: false,
  lens_imsClientId: 'Activation-DTM', // we should make this configurable so you can change it for local shell and testing
  // # At some future point, we should probably remove `audiencemanager_api` scope and put pressure on the Audience Manager extension owners to exchange the token for a new one with the scopes they need.
  lens_imsScopes: ['openid','AdobeID','session','read_organizations','additional_info.projectedProductContext','additional_info.job_function','additional_info.roles','audiencemanager_api'],
  lens_imsEcConfigKey: 'production',
  lens_environmentName: 'production',
  lens_debug: false,
  //https://launch.adobe.com/tags/companies/CO426eab730af74f248b30516bf3cb22d6/properties/PR906238a59bbf4262bcedba248f483604/overview
  lens_dtmUri: '//assets.adobedtm.com/8330971d67b94bd77a277617373beae16eb9f12c/satelliteLib-2ca5ab87343f3c882e4ab68f2f6a886eaea6e40b.js',
  lens_typekitId: 'buj6cmr',
  lens_errorReportingEnabled: true,
  lens_airbrakeProjectId: 132821,
  lens_airbrakeProjectKey: 'e3a6fed7642a9d2b3fda5f17c5d6b83b',
  api_protocol: 'https',
  api_domain: 'reactor.adobe.io',
  api_port: 443,
  // this is used to reinject the url params when navigating in the app.jsx
  lens_configUrlParamKeys: Object.keys(urlParams),
  lens_spaPiplineEnabled: window.location.pathname.startsWith('/solutions'),
};

if (!defaultEnvironmentSettings.lens_spaPiplineEnabled) {
  defaultEnvironmentSettings.lens_publicAssetsPath = '/';
  defaultEnvironmentSettings.lens_publicAppPath = '/';
}

const environmentSettings = defaultEnvironmentSettings;

environmentSettings.lens_env = urlParams.lens_env || environmentSettings.lens_env;

if (environmentSettings.lens_env === 'qa') {
  environmentSettings.lens_imsEcConfigKey = 'qa';
  environmentSettings.api_domain = 'reactor-qe.adobe.io';
  environmentSettings.lens_environmentName = 'qe';
  environmentSettings.api_port = 443;
}

if (environmentSettings.lens_env === 'engd') {
  environmentSettings.lens_imsEcConfigKey = 'qa';
  environmentSettings.api_domain = 'reactor-engd.adobe.io';
  environmentSettings.lens_environmentName = 'engd';
  environmentSettings.api_port = 443;
}

if (environmentSettings.lens_env === 'stage') {
  environmentSettings.lens_imsEcConfigKey = 'production';
  environmentSettings.api_domain = 'reactor-stage.adobe.io';
  environmentSettings.lens_environmentName = 'stage';
  environmentSettings.api_port = 443;
}

if (environmentSettings.lens_env === 'dev') {
  environmentSettings.lens_imsEcConfigKey = 'qa';
  environmentSettings.api_domain = 'reactor-dev.adobe.io';
  environmentSettings.lens_environmentName = 'dev';
  environmentSettings.api_port = 443;
}

if (environmentSettings.lens_env === 'demo') {
  environmentSettings.lens_imsEcConfigKey = 'production';
  environmentSettings.api_domain = 'reactor-demo.adobe.io';
  environmentSettings.lens_environmentName = 'demo';
  environmentSettings.api_port = 443;
}

if (environmentSettings.lens_env === 'prod') {
  environmentSettings.lens_imsEcConfigKey = 'production';
  environmentSettings.api_domain = 'reactor.adobe.io';
  environmentSettings.lens_environmentName = 'prod';
  environmentSettings.api_port = 443;
}

if (environmentSettings.lens_dev) {
  environmentSettings.lens_environmentName = 'local';
  environmentSettings.lens_dtmUri = '//assets.adobedtm.com/7568de40bae2/9e4fc9beaac5/launch-cc97c8075f07-development.js';
  environmentSettings.lens_errorReportingEnabled = false;
}

environmentSettings.lens_imsEcConfig = configs[environmentSettings.lens_imsEcConfigKey];


ALLOWED_CONFIG_PARAM_KEYS.forEach((paramKey)=>{
  if (urlParams[paramKey] !== undefined) {
    environmentSettings[paramKey] = urlParams[paramKey];
  }
});


window.environmentSettings = new Proxy(
  environmentSettings,
  {
    get(environmentSettings, prop) {
      if (prop === 'api_url') {
        return (
          environmentSettings.api_protocol +
          '://' +
          environmentSettings.api_domain +
          (environmentSettings.api_port ? ':' + environmentSettings.api_port : '') +
          (environmentSettings.path ? environmentSettings.path : '')
        ).replace(/\:443|:80/, ''); // the browser removes these 2 ports so we'll match that behavior
      }

      return environmentSettings[prop];
    },
    set(environmentSettings, prop, value) {
      if (prop === 'lens_configUrlParamKeys') {
        environmentSettings[prop] = value;
      }

      // Indicate success
      return true;
    }
  }
);



// launch on launch
var lens_launchDisabled;
try {lens_launchDisabled = window.localStorage.getItem('lens_launchDisabled');} catch (e) {console.log(e);}
if (lens_launchDisabled !== 'true') {
  var dtmEmbedScript = document.createElement('script');
  dtmEmbedScript.src = window.environmentSettings.lens_dtmUri;
  dtmEmbedScript.setAttribute('id', 'dtm-embed');
  dtmEmbedScript.setAttribute('async', '');
  document.getElementsByTagName('head')[0].appendChild(dtmEmbedScript);
}
